import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Store } from '@ngxs/store';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { BazaWebAccountEnI18n, BazaWebPFEnI18n } from '@scaliolabs/baza-web-purchase-flow';
import { BazaWebUiEnI18n } from '@scaliolabs/baza-web-ui-components';
import { BazaWebUtilSharedService } from '@scaliolabs/baza-web-utils';
import { BazaWebVFEnI18n } from '@scaliolabs/baza-web-verification-flow';
import { RequestAppBootstrapInitData, StartApp, UiI18n } from '@scaliolabs/web/data-access';
import { AccountI18n } from '@scaliolabs/web/feature-account';
import { PurchaseI18n } from '@scaliolabs/web/feature-purchase';
import { VerificationI18n } from '@scaliolabs/web/feature-verification';
import { merge } from 'lodash';
@UntilDestroy()
@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.less'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AppComponent implements OnInit {
    constructor(
        private readonly store: Store,
        private readonly translate: TranslateService,
        public readonly wts: BazaWebUtilSharedService,
    ) {}

    ngOnInit(): void {
        this.configureI18nTranslations();

        // start the app
        this.store.dispatch(new StartApp());

        this.wts.refreshInitData$.pipe(untilDestroyed(this)).subscribe(() => {
            this.store.dispatch(new RequestAppBootstrapInitData());
        });
    }

    configureI18nTranslations() {
        const defaulti18n = { ...BazaWebUiEnI18n, ...BazaWebAccountEnI18n, ...BazaWebVFEnI18n, ...BazaWebPFEnI18n };
        const overridei18n = {};
        // const overridei18n = { ...UiI18n, ...AccountI18n, ...VerificationI18n, ...PurchaseI18n };

        const EnI18n = merge(defaulti18n, overridei18n);
        this.translate.setTranslation('en', EnI18n);
    }
}
