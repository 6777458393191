export const WebUiMarketingHeroEnI18n = {
    section0: {
        btnCTA: {
            label: `<a data-link="heroCTALink"></a>`,
        },
    },
    linkConfigs: [
        {
            key: `heroCTALink`,
            commands: ['/items'],
            text: `Get Started`,
        },
    ],
};
