import { NgModule } from '@angular/core';
import { ViewportScroller } from '@angular/common';
import { Event, NavigationCancel, Router, RouterModule, Routes, Scroll } from '@angular/router';
import { filter, tap } from 'rxjs/operators';
import { MetaGuard } from '@ngx-meta/core';
import {
    BazaRegistryNgResolve,
    JwtRequireAuthGuard,
    JwtRequireNoAuthGuard,
    JwtVerifyGuard,
    MaintenanceGuard,
} from '@scaliolabs/baza-core-ng';
import { CartResolver } from '@scaliolabs/baza-web-purchase-flow';
import { VerificationResolver } from '@scaliolabs/baza-web-verification-flow';
import { LayoutComponent, LayoutModule } from '@scaliolabs/web/feature-layout';
import { CartGuard, CART_IS_EMPTY, BootstrapResolver, MarketingResolver } from '@scaliolabs/web/data-access';

export const routes: Routes = [
    {
        path: 'maintenance',
        pathMatch: 'full',
        loadChildren: () => import('@scaliolabs/web/feature-maintenance').then((m) => m.MaintenanceModule),
        canActivate: [MaintenanceGuard],
    },
    {
        path: '',
        resolve: {
            bootstrap: BootstrapResolver,
            sections: MarketingResolver,
            __baza_core_registry: BazaRegistryNgResolve,
        },
        children: [
            {
                path: '',
                component: LayoutComponent,
                canActivateChild: [MetaGuard],
                children: [
                    {
                        path: '',
                        loadChildren: () => import('@scaliolabs/web/feature-home').then((m) => m.HomeModule),
                    },
                    {
                        path: 'mail',
                        loadChildren: () => import('@scaliolabs/web/feature-mail').then((m) => m.MailModule),
                    },
                    {
                        path: 'items',
                        loadChildren: () => import('@scaliolabs/web/feature-item').then((m) => m.ItemModule),
                    },
                    {
                        path: 'news',
                        loadChildren: () => import('@scaliolabs/web/feature-news').then((m) => m.NewsModule),
                    },
                    {
                        path: 'portfolio',
                        canActivateChild: [JwtRequireAuthGuard, JwtVerifyGuard],
                        loadChildren: () => import('@scaliolabs/web/feature-portfolio').then((m) => m.PortfolioModule),
                    },
                    {
                        path: 'verification',
                        canActivateChild: [JwtRequireAuthGuard, JwtVerifyGuard],
                        loadChildren: () => import('@scaliolabs/web/feature-verification').then((m) => m.VerificationModule),
                    },
                    {
                        path: 'buy-shares',
                        canActivateChild: [JwtVerifyGuard, JwtRequireAuthGuard, CartGuard],
                        loadChildren: () => import('@scaliolabs/web/feature-purchase').then((m) => m.PurchaseModule),
                        resolve: {
                            purchase: CartResolver,
                            verification: VerificationResolver,
                        },
                    },
                    {
                        path: 'account',
                        canActivateChild: [JwtVerifyGuard, JwtRequireAuthGuard],
                        loadChildren: () => import('@scaliolabs/web/feature-account').then((m) => m.AccountModule),
                    },
                    {
                        path: 'favorites',
                        canActivateChild: [JwtVerifyGuard, JwtRequireAuthGuard],
                        loadChildren: () => import('@scaliolabs/web/feature-favorite').then((m) => m.FavoriteModule),
                    },
                    {
                        path: '**',
                        loadChildren: () => import('@scaliolabs/web/feature-not-found').then((m) => m.NotFoundModule),
                    },
                ],
            },
            {
                outlet: 'modal',
                path: 'auth',
                loadChildren: () => import('@scaliolabs/web/feature-auth').then((m) => m.AuthModule),
                canActivate: [JwtRequireNoAuthGuard],
            },
            {
                outlet: 'modal',
                path: 'contact-us',
                loadChildren: () => import('@scaliolabs/web/feature-contact-us').then((m) => m.ContactUsModule),
            },
        ],
    },
];

@NgModule({
    imports: [
        LayoutModule,
        RouterModule.forRoot(routes, {
            paramsInheritanceStrategy: 'always',
            // Notice custom routing logic down below
            scrollPositionRestoration: 'disabled',
            anchorScrolling: 'disabled',
            onSameUrlNavigation: 'reload',
            initialNavigation: 'enabledBlocking',
        }),
    ],
    exports: [RouterModule],
})
export class AppRoutingModule {
    constructor(private router: Router, private viewportScroller: ViewportScroller) {
        this.router.events
            .pipe(
                filter((e: Event): e is Scroll => e instanceof Scroll),
                tap((current) => {
                    const state = this.router.getCurrentNavigation()?.extras?.state;

                    if (state?.skipScroll) {
                        return;
                    }
                    if (current.position) {
                        // Backward navigation
                        this.viewportScroller.scrollToPosition(current.position);
                    } else if (current.anchor) {
                        // Anchor navigation
                        this.viewportScroller.scrollToAnchor(current.anchor);
                    } else {
                        // Page navigation
                        this.viewportScroller.scrollToPosition([0, 0]);
                    }
                }),
            )
            .subscribe();

        this.router.events.pipe(filter((event) => event instanceof NavigationCancel)).subscribe((event: any) => {
            switch (event.reason) {
                case CART_IS_EMPTY:
                    this.router.navigate(['/items']);
                    break;
            }
        });
    }
}
