export const WebUiMarketingCTAEnI18n = {
    section0: {
        btnCTA: {
            label: `<a data-link="ctaLink"></a>`,
        },
    },
    linkConfigs: [
        {
            key: `ctaLink`,
            commands: ['/items'],
            text: `Get Started`,
        },
    ],
};
